<template>
  <div class="container">

    <div class="content">
      <!-- 查找类型 -->
      <div class="header-item">
        <el-radio-group  v-model="searchType" @change="getList(1,searchType)">
          <el-radio-button :label=2 >全部</el-radio-button>
          <el-radio-button :label=1  >已通过</el-radio-button>
          <el-radio-button :label=3  >待审核</el-radio-button>
          <!-- <el-badge :value="12" class="item">
            <el-radio-button :label=3  >待审核</el-radio-button>
          </el-badge> -->
          
          <el-radio-button :label=0  >已拒绝</el-radio-button>
        </el-radio-group>
      </div>
      <!-- 搜索框 -->
      <div class="header-item">
        <el-input v-model="params.name" placeholder="请输入企业名称" clearable @clear="getList(1)"  suffix-icon="el-icon-search">
        </el-input>
      </div>
      <div class="header-item">
        <el-button type="primary" icon="el-icon-search" @click="getList(1)">搜索</el-button>
      </div>
      <!-- 未申请的企业 -->
      <div style="float:right;margin-right:10px" >
        <el-button type="primary"  icon="el-icon-search" @click="goApplyPage">未申请的企业</el-button>
      </div>
      <!--  未申请的企业表格数据-->
      <el-dialog title="未申请的企业" :visible.sync="applyTableVisible">
        <div >
             <!-- 搜索框 -->
          <div class="header-item">
            <el-input v-model="applyparams.name"   placeholder="请输入企业名称" clearable @clear="getApplyList(1)" suffix-icon="el-icon-search">
            </el-input>
          </div>   
          <div class="header-item">
            <el-button type="primary" @click="getApplyList(1)" icon="el-icon-search">搜索</el-button>
          </div>
          <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
            header-row-class-name="table-header-style"
            :data="dataApplyList"
            :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
            <el-table-column label="公司名称">
              <template slot-scope="scope">
                {{scope.row.name || '无'}}
              </template>
            </el-table-column>
            <el-table-column label="主要负责人"  >  
              <template slot-scope="scope">
                {{scope.row.applicant || '无'}}
              </template>
            </el-table-column>
            <el-table-column label="使用人数" width="100px" align="center" >
              <template slot-scope="scope">
                {{scope.row.member_limit || '无'}}
              </template>
            </el-table-column>
            <el-table-column label="注册时间"  align="center" >
              <template slot-scope="scope">
                {{scope.row.expire_at | dateFormat}}
              </template>
            </el-table-column>
            <el-table-column label="联系电话" width="80px" align="center" >
              <template slot-scope="scope">
                {{scope.row.tel || '无'}}
              </template>
            </el-table-column>
          </el-table>
          <!-- 未申请分页 -->
          <div style="margin-top: 20px; overflow: hidden">
            <div style="float: right">
              <el-pagination @current-change="getApplyList" layout="total, prev, pager, next, jumper"
                :background="true"
                :current-page="applyparams.pageIndex"
                :page-size="applyparams.pageNumber"
                :total="applytotal">
              </el-pagination>
            </div>
          </div>
        </div>
      </el-dialog>
      <!-- 企业列表及操作 -->
      <div >
        <el-table ref="multipleTable" tooltip-effect="dark" style="width: 100%; margin-top: 10px" 
          header-row-class-name="table-header-style"
          :data="dataList"
           @selection-change="selectHandle"
          :header-cell-style="{'background-color': 'rgba(249, 249, 249)'}">
           <el-table-column type="selection"  width="55" ></el-table-column>
          <el-table-column label="营业执照" width="100" >
            <template slot-scope="scope">
              <div v-if="scope.row.scope" @click="preview(scope.row)" class="item-img" :style="{
                'background-image': `url(${scope.row.scope })`}">
              </div>
              <div v-else  class="item-img" @click="npreview" :style="{
                'background-image': `url(${$configs.headImg })`}">
              </div>
            </template>
          </el-table-column>        
          <el-table-column label="营业执照注册号" width="220">
            <template slot-scope="scope">
               {{scope.row.regis || '无'}}
            </template>
          </el-table-column>
          <el-table-column label="申请人" width="160" >
            <template slot-scope="scope">
               {{scope.row.applicant || '无'}}
            </template>
          </el-table-column>
          <el-table-column label="申请公司"  >  
            <template slot-scope="scope">
               {{scope.row.name || '无'}}
            </template>
          </el-table-column>
          <el-table-column label="联系方式"   width="190">
            <template slot-scope="scope">
               {{scope.row.tel || '无'}}
            </template>
          </el-table-column>
          <el-table-column label="状态" width="100" >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1">已通过</span>
              <span v-else-if="scope.row.status == 0">已拒绝</span>
              <span v-else-if="scope.row.status == 2">未申请</span>
              <span v-else>审核中</span>
            </template>
          </el-table-column>
          <!-- 通过拒绝 -->
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <div v-if="scope.row.status == 1">
                  --
              </div>
              <div class="" v-else-if="scope.row.status == 0">
                 {{scope.row.reason || '无'}}
              </div>
              <div v-else>
                <el-button  type="text" @click="pass(scope.row)">通过</el-button>
                <el-button  type="text" @click="refuse(scope.row)" style="color: #ff0000">拒绝</el-button> 
              </div>
                  
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div style="margin-top: 20px; overflow: hidden">
           <el-button :disabled="!selectList" @click="allPassed()" >批量通过</el-button>
          <div style="float: right">
            <el-pagination @current-change="getList" layout="total, prev, pager, next, jumper"
              :background="true"
              :current-page="params.pageIndex"
              :page-size="params.pageNumber"
              :total="total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResourceDialog from '@/websites/cms/js/dialog';
import { localStorage } from '@/js/Utils';
export default {
  data(){
    return {
      searchType:2,
      params:{
        name:'',
        status:'',
        pageNumber:6,
        pageIndex:0
      },
      dataList:[],
      total:0,
      selectList:'',//当前选中的所以对象id
      refuseValue:'',
      queryStatus:'',
      passStatus:[],//批量通过时选中的状态
      applyTableVisible:false,//未申请企业dialog状态


      applyparams:{
        name:'',
        pageNumber:6,
        pageIndex:0
      },
      dataApplyList:[],
      applytotal:0,
    }
  },
  created() {
   
    this.getList(1);
    this.getApplyList(1);
  },
  methods: {
        //获取Apply列表
    async getApplyList(page) {
      this.applyparams.pageIndex=page;
      if(this.applyparams.name =='') delete this.applyparams.name;
      console.log(this.applyparams);
      let res = await this.$ZGManager.companysApp(this.applyparams)
      if (res.status !== 200)return;
      this.dataApplyList = res.data.list;
      this.applytotal = res.data.total_rows;
    },
    //获取列表
    async getList(page,status) {   
      this.params.status = this.searchType;
      if(this.params.name =='') delete this.params.name;
      if(this.params.status == 2) this.params.status = '';
      if(this.params.status ==='') delete this.params.status;
      this.params.pageIndex=page;
      let res = await this.$ZGManager.companyList(this.params);
      if (res.status !== 200)return;
      this.dataList = res.data.list;
      this.total = res.data.total_rows;
    },

    async pass(row) {
      let id = row.id;
      let res = await this.$ZGManager.companyStatus(id);
       if (res.status !==200) {
          return;
       }
       this.getList(this.params.pageIndex)
        this.$message({
          type: 'success',
          message: '通过审核成功'
          });
    },
    //选中之后
    selectHandle(val) {
      // console.log(val);
      this.selectList = '';
      this.passStatus = [];
      val.map(e=> {
        this.selectList += `${e.id},`
        this.passStatus.push(e.status)
      });
      this.selectList = this.selectList.substring(0,this.selectList.length-1);
      // console.log(this.selectList);
      // console.log(this.passStatus);
    },
    //批量通过
    async allPassed() {
      console.log(this.selectList)
      let flag ;
      for(let i =0;i<this.passStatus.length;i++) {
         console.log(this.passStatus);
       if(this.passStatus[i] !==3) {
        flag = false;
        break
       }else {
          flag = true;
       }
     }
      console.log(flag);
      if (flag) {
          let res = await this.$ZGManager.companyStatus(this.selectList);
        if (res.status !==200) {
          return;
        }
        this.getList(this.params.pageIndex)
        this.$message({
          type: 'success',
          message: '通过审核成功'
        });
      } else {
          this.$message({
            type: 'warning',
            message: '当前选中已审核过'
        });
      }
  
     
    },
    //拒绝原因弹出
    async refuse(row) {
      try {
        let dialog = await this.$prompt('请输入拒绝原因',{
          confirmButtonText:'确定',
          cancelButtonText: '取消',
          // inputPattern: /^[\u4E00-\u9FA5]{3,20}(·[\u4E00-\u9FA5]{1,4})?$/,
          inputPattern: /^[\u4E00-\u9FA5]{3,20}?$/,
          inputErrorMessage: '请输入3-20中文字数',
          inputValue:this.refuseValue, 
        });
        if(dialog.action != 'confirm') return;
        console.log(dialog.value);
        let params = {
         reason:dialog.value,
         status:0
        }
        console.log(params);
        let res = await this.$ZGManager.updataCompanies(row.id,params);
         if (res.status !==200) {
          return;
       }
        this.getList(this.params.pageIndex)
        this.$message({
          type: 'success',
          message: '拒绝通过完成'
          });
        console.log(res);
      } catch (error) {
        console.log(error);
      }

    },
    //跳转到未申请的企业页
    goApplyPage() {
      // let routePage = this.$router.resolve({
      //   path:'/qllcompany/apply'
      // }) 
      // window.open(routePage.href,'_blank')
      this.applyTableVisible = true;
    },
    // 预览海报
    preview(item){
      console.log(item);
      ResourceDialog.show({
        type: 'previewResource',
        resource: item,
        resourceType: 'image',
        resourceUrl: item.scope,
        resourceTitle: "营业执照",
      })
    },
    npreview() {
      this.$message.warning('暂无营业执照信息')
    }
    //stringify()
   
  }
}
</script>
<style lang="scss" scoped>
.content{
  padding: 10px;
  background-color: #fff;
  margin-bottom: 10px;
  font-size: 14px;
  .header-item{
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 10px;
    vertical-align: middle
  }
  .item-img{
    width: 60px;
    height: 60px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover;
    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }
  .reason {
    overflow: hidden;
  }
}
::v-deep .el-dialog {
    max-height: 80%;
    overflow: auto;
    box-shadow: 0 1px 3px rgba(216, 211, 211, 0.05);
  }
::v-deep .el-image-viewer__canvas {
  img {
    height: 600px;
    width: 800px;
  }
}
  ::v-deep .el-badge__content {
    top: 5px;
    right: 30px;
  }

</style>